import React from "react";
import linkedinFooter from "../../../static/linkedin-footer.svg"
import facebookFooter from "../../../static/facebook-footer.svg"
import twitterFooter from "../../../static/twitter-footer.svg"
import instaFooter from "../../../static/insta-footer.svg"
import youtubeFooter from "../../../static/youtube-footer.svg"
import linkedinFooterWhite from "../../../static/linkedin-footer-white.svg"
import facebookFooterWhite from "../../../static/facebook-footer-white.svg"
import twitterFooterWhite from "../../../static/twitter-footer-white.svg";
import instaFooterWhite from "../../../static/insta-footer-white.svg";
import youtubeFooterWhite from "../../../static/youtube-footer-white.svg";
import findSiteType from "../helpers/findSiteType";
const NavbarSocialIcons = ({ large = false }) => {
  const classSize = large ? 'fa-2x' : '';
  const navSocialIconsStyle = "tw-items-center tw-inline-flex tw-h-[1.5rem] tw-justify-center tw-w-[1.5rem] md:tw-w-[2rem]"
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');

  return (
    <>
      <a
        href="https://www.linkedin.com/company/accruent"
        aria-label="Accruent on LinkedIn"
        target="_blank"
      >
        <span className={navSocialIconsStyle}>
          <img className="svg-inline--fa fa-linkedin-in tw-h-4" data-prefix="fab" src={isMc || isEms ? linkedinFooterWhite : linkedinFooter} decoding="async"/>
          {/* <svg class="svg-inline--fa fa-linkedin-in tw-h-4" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg> */}
        </span>
      </a>
      <a
        href="https://www.facebook.com/Accruent"
        aria-label="Accruent on Facebook"
        target="_blank"
      >
        <span className={navSocialIconsStyle}>
          <img className="svg-inline--fa fa-facebook-f tw-h-4" src={isMc || isEms ? facebookFooterWhite : facebookFooter} decoding="async"/>
          {/* <svg class="svg-inline--fa fa-facebook-f tw-h-4" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg> */}
        </span>
      </a>
      <a
        href="https://twitter.com/accruentllc"
        aria-label="Accruent on Twitter"
        target="_blank"
      >
        <span className={navSocialIconsStyle}>
          <img className="svg-inline--fa fa-x-twitter tw-h-4" src={isMc || isEms ? twitterFooterWhite :twitterFooter} decoding="async"/>
          {/* <svg class="svg-inline--fa fa-x-twitter tw-h-4" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg> */}
        </span>
      </a>
      <a
        href="https://www.instagram.com/accruentllc/"
        aria-label="Accruent on Instagram"
        target="_blank"
      >
        <span className={navSocialIconsStyle}>
          <img src={isMc || isEms ? instaFooterWhite : instaFooter} className="svg-inline--fa fa-instagram tw-h-4"/>
          {/* <svg class="svg-inline--fa fa-instagram tw-h-4" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg> */}
        </span>
      </a>
      <a
        href="https://www.youtube.com/@AccruentOfficial?app=desktop"
        aria-label="Accruent on Youtube"
        target="_blank"
      >
        <span className={navSocialIconsStyle}>
          <img src={isMc || isEms ? youtubeFooterWhite : youtubeFooter} className="svg-inline--fa fa-youtube tw-h-4 tw-w-7"/>
          {/* <svg class="svg-inline--fa fa-youtube tw-h-4 tw-w-7" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"></path></svg> */}
        </span>
      </a>
    </>
  );
}

export default NavbarSocialIcons;