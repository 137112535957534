import React, {useContext, useEffect, useState} from "react";
import {LocalizedLink} from "src/components/commonComponents";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import site_vars from "src/get_site_data";
import NavbarSocialIcons from "src/components/navigation/NavbarSocialIcons";
import findSiteType from "src/components/helpers/findSiteType";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "./_footer.module.scss"
const detailUrl = findSiteType('mc')?'/cookie':findSiteType('ems')?'/cookie-list':'/cookies';

const FooterWithCopywrite = ({footerProps}) =>  {
  const { footerData, landingPageFooterData } = useContext(ResourcesContext);
  const [isEms, setIsEms] = useState(false);
  const [isAcc, setIsAcc] = useState(false);
  let pageType = footerProps?.pageType
  const landingPageFooterSize = landingPageFooterData?.length;

  useEffect(() => {
    setIsEms(site_vars.drupal_site_id === 452);
    setIsAcc(site_vars.drupal_site_id === 451)
  }, [site_vars]);

  return (
    <div className={styles.footerContainer + " content-visibility-auto-mobile"}>
    <div className={`footer-wrapper new-footer landing-nav ${isEms? 'is-ems': ""}`} id="footer-copywrite">
      {pageType && isEms && pageType === "landing_page" && <div className="landing-ems-footer-img">
        <StaticImage src="../../../../static/ems-footer.png" alt="EmsLandingFooterImg" decoding = "async" />
      </div>}
      <div className={isEms ?`columns footer-top landing-nav ems` : `footer-top-new tw-bg-[#ededed] tw-py-[49px] tw-relative tw-flex lg:tw-text-right tw-flex-col lg:tw-flex-row tw-text-center`}>
        <div className={`footer-logo tw-w-full lg:tw-w-[25%] tw-mt-0 tw-p-0 ${isEms ? 'is-ems' : isAcc ? 'is-acc' : ''}`}>
            <LocalizedLink to={footerProps?.locale === 'en' ? '/' : '/uk'}>
              <img
                src={site_vars.logo}
                alt="Home"
                className="tw-inline-block tw-w-[196px]"
                decoding = "async"
              />
            </LocalizedLink>
          {/* {isAcc && (
            <div className="column is-12 p-0 is-center footer-social-icons is-right landing-nav">
              <div className="py-0 footer-social-icons-content">
                <div>
                  Follow us
                </div>
                <div>
                  <NavbarSocialIcons />
                </div>
              </div>
            </div>
          )} */}
          <div className={`footer-border-top tw-top-[0px] tw-w-[24.5%] tw-rounded-[30px] tw-h-[6px] tw-left-[-3px] tw-absolute ${isEms ? 'is-ems' : isAcc ? ' tw-bg-[#592b82]' : ''}`}></div>
        </div>
        <div className="footer-copywrite landing-nav lg:tw-pl-[400px]">

          {pageType && pageType === "landing_page" ?
            <div className="column is-12 landing-nav-notice">
              <span className=" tw-text-[14px] tw-text-[#3a3a3a]">© Copyright Accruent {new Date().getFullYear()}&nbsp;&nbsp;{'|'}&nbsp;&nbsp;</span>
              {landingPageFooterData?.map((item, i) => {
                return (
                   <span><LocalizedLink className=" tw-text-[#555] tw-text-[14px] hover:tw-text-[#242424] hover:tw-underline" target={"_blank"} to={item.link}>{item.name}</LocalizedLink>{!(i<(landingPageFooterSize-1)) || <>&nbsp;&nbsp;{'|'}&nbsp;&nbsp;</>}</span>
                )
              })}
            </div>
            :
            footerData && footerData.map(item => (
              <div className="column is-3-desktop is-6-mobile column-wrapper" key={item.name}>
                <span className="title">
                  {item.name}
                </span>
                <div className="subtitle-wrapper">
                  {item.children && item.children.map(itemChild => (
                    <ul aria-labelledby={itemChild.name} key={itemChild.name}>
                      <li className="subtitle">
                        <LocalizedLink to={itemChild.link}>{itemChild.name}</LocalizedLink>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      {/* <div className="columns footer-bottom landing-nav">
        <div className={`column is-3 footer-logo ${isAcc? 'pt-1 is-acc' : ''}`}>
          {isAcc ? (
            <div className="column is-12 py-0 my-0 has-text-weight-bold footer-bottom-text">
              © Copyright Accruent {new Date().getFullYear()}.
            </div>
          ) : (
            <>
              <div className="column is-12 py-0">
                <LocalizedLink to="https://www.accruent.com/">
                  <img
                    src="/accruent-logo-white.svg"
                    alt="Home"
                    className="tw-inline"
                  />
                </LocalizedLink>
              </div>
              <div className="column is-12 footer-social-icons">
                <NavbarSocialIcons />
              </div>
            </>
          )}
        </div>
        <div className="columns column is-multiline is-9-desktop is-12-mobile footer-bottom-text">
          {!isAcc && (
            <div className="column is-12 py-1 is-hidden-mobile">
              <LocalizedLink to="https://www.accruent.com/about-us" alt="About Us">About Us</LocalizedLink>{' | '}
              <LocalizedLink to="https://www.accruent.com/about-us/careers" alt="Careers">Careers</LocalizedLink>{' | '}
              <LocalizedLink to="https://www.accruent.com/about-us/our-leadership ">Leadership</LocalizedLink>{' | '}
              <LocalizedLink to="https://www.accruent.com/partners">Partners</LocalizedLink>{' | '}
              <LocalizedLink to="https://www.accruent.com/resources/all-press-releases">Press Releases</LocalizedLink>
            </div>
          )}
          <div className="column is-12 py-1">
            <LocalizedLink to="https://www.accruent.com/gdpr">GDPR Notice</LocalizedLink>{' | '}
            <LocalizedLink to="https://www.accruent.com/legal-notice">Legal Notice</LocalizedLink>{' | '}
            <br />
            <LocalizedLink to="https://www.accruent.com/privacy-notice">Privacy Notice</LocalizedLink>{' | '}
            <LocalizedLink to="https://www.accruent.com/terms-use">Terms of Use</LocalizedLink>{' | '}
            <br />
            <LocalizedLink to={detailUrl}>Cookie Detail</LocalizedLink>{' '}
            <a className="cookie-detail-link ot-sdk-show-settings" id="ot-sdk-btn">
              Cookies Settings
            </a>
          </div>
          {!isAcc && <div className="column is-12 pt-3 has-text-weight-bold">
            © Copyright Accruent {new Date().getFullYear()}.
          </div>}
        </div>
      </div> */}
    </div>
    </div>
  );
}

export default FooterWithCopywrite;
